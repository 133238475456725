import { TemplateProps } from '../heineken_template/_private/TemplateProps'
import { store } from '~/pages/heineken_template/_private/store'
import { css } from '@emotion/react'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { Daddy960_TopBar } from '../daddy960_opkevin/component/daddy960_TopBar'
import { Charting } from '~/pages/heineken_template/_private/Charting'
import { David0705_SidePane2 } from './david0705_SidePane2'
import { david0705_initStrategies } from './david0705_initStrategies'
import { David0705_SidePane1 } from './david0705_SidePane1'
import { David0705_SidePaneMenu } from './david0705_SidePaneMenu'
import { David0705_Footer } from './david0705_Footer'
import { TradingSummary } from './trading_analyzer/TradingAnalyzer'
import { ChipsOverView } from './chips_overview/ChipsOverview'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { AgentCarouselSubscriptionCheck } from '~/modules/auth/components/AgentCarouselSubscriptionCheck'
import { Preset_LoginPageOfCarousel } from '../heineken_template/_preset/preset_LoginPageOfCarousel'
import { David0705_BottomMenu } from './david0705_BottomMeun'

export const david0705_init = {
  global(templateProps: TemplateProps) {
    useThemeStore.setState({ theme: 'dark' })
    store.charting.setThemeMode('dark')

    templateProps.layout.Charting = undefined
    templateProps.layout.Drawer1 = David0705_SidePane1
    templateProps.layout.Row1 = <Daddy960_TopBar leftBurger={true} />
    templateProps.layout.Row2 = David0705_Footer
  },

  indexPage(templateProps: TemplateProps) {
    david0705_init.global(templateProps)
    david0705_initStrategies()
    //goodwayStore.pageState = 'os-monitors'

    useThemeStore.setState({ theme: 'dark' })
    store.charting.setThemeMode('dark')

    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web')

    store.charting.widgetOptions = {
      ...store.charting.widgetOptions,
      symbol: 'TX-1',
      interval: '5',
      enableVolumeIndicator: false,
      disabledHeaderWidget: false,
      disabledLeftToolbar: false,
      disabledPaneMenu: true,
      disabledTimeframesToolbar: true,
      disabledHeaderChartType: true,
      disabledHeaderSaveload: false,
      disabledHeaderCompare: false,
      overrides: {
        ...store.charting.darkOverrides,
        'paneProperties.legendProperties.showLegend': false,
        'paneProperties.topMargin': 5,
        'paneProperties.bottomMargin': 5,
        'scalesProperties.fontSize': 14,
        'paneProperties.backgroundType': 'gradient',
        'paneProperties.backgroundGradientStartColor': '#141517',
        'paneProperties.backgroundGradientEndColor': '#171718',
        'paneProperties.vertGridProperties.color': '#333333',
        'paneProperties.horzGridProperties.color': '#333333',
      },
    }

    templateProps.layout.cssset = css`
      grid-template-rows: 48px 48px calc(100vh - 48px) 64px;
      grid-template-columns: 336px 336px 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Row3 Row3 Row3'
        'Drawer1 Drawer2 Chart'
        'Row2 Row2 Row2';

      ${createIPadCss(css`
        grid-template-rows: 48px calc(100vh - 96px) 48px 64px;
        grid-template-columns: 200px 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Row3 Row3 Row3'
          'Row2 Row2 Row2';
      `)}

      ${createMobileCss2(css`
        grid-template-rows: 48px calc(100vh - 160px) 48px 64px;
        grid-template-columns: 200px 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Row3 Row3 Row3'
          'Row2 Row2 Row2';
      `)}
    `

    // Init components
    templateProps.layout.Charting = Charting
    templateProps.layout.Row1 = <Daddy960_TopBar leftBurger={true} />
    templateProps.layout.Row2 = David0705_Footer
    templateProps.layout.Row3 = David0705_BottomMenu
    templateProps.layout.Drawer1 = David0705_SidePane1
    templateProps.layout.Drawer2 = David0705_SidePane2
    //templateProps.layout.Col1 = David0705_SidePaneMenu
    templateProps.layout.login = <Preset_LoginPageOfCarousel resources={[{ image: [true, ''] }]} />
  },
  ['trading_analyzer/index.page'](templateProps: TemplateProps) {
    david0705_init.global(templateProps)
    //goodwayStore.pageState = 'os-monitors'

    useThemeStore.setState({ theme: 'dark' })
    store.charting.setThemeMode('dark')
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web')

    templateProps.layout.cssset = css`
      grid-template-rows: 48px 48px calc(100vh - 48px) 64px;
      grid-template-columns: 200px 336px 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Row3 Row3 Row3'
        'Drawer1 Drawer2 Chart'
        'Row2 Row2 Row2';

      ${createIPadCss(css`
        grid-template-rows: 48px calc(100vh - 96px) 48px 64px;
        grid-template-columns: 200px 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Row3 Row3 Row3'
          'Row2 Row2 Row2';
      `)}

      ${createMobileCss2(css`
        grid-template-rows: 48px calc(100vh - 160px) 48px 64px;
        grid-template-columns: 200px 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Row3 Row3 Row3'
          'Row2 Row2 Row2';
      `)}
    `

    // Init components
    templateProps.layout.Row1 = (
      <Daddy960_TopBar
        leftBurger={true}
        rightBerger={false}
      />
    )
    templateProps.layout.Row2 = David0705_Footer
    templateProps.layout.Col1 = David0705_SidePaneMenu
    templateProps.layout.Col2 = TradingSummary
    templateProps.layout.login = <Preset_LoginPageOfCarousel resources={[{ image: [true, ''] }]} />
  },
  ['chips_overview/index.page'](templateProps: TemplateProps) {
    david0705_init.global(templateProps)
    //goodwayStore.pageState = 'os-monitors'

    useThemeStore.setState({ theme: 'dark' })
    store.charting.setThemeMode('dark')
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web')

    templateProps.layout.cssset = css`
      grid-template-rows: 48px calc(100vh - 48px) 64px;
      grid-template-columns: 200px 336px 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Col1 Drawer1 Col2'
        'Row2 Row2 Row2';

      ${createIPadCss(css`
        grid-template-rows: 48px 1fr 64px;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col2 Col2 Col2'
          'Row2 Row2 Row2';
      `)}

      ${createMobileCss2(css`
        grid-template-rows: 48px 1fr 64px;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col2 Col2 Col2'
          'Row2 Row2 Row2';
      `)}
    `

    // Init components
    templateProps.layout.Row1 = (
      <Daddy960_TopBar
        leftBurger={true}
        rightBerger={false}
      />
    )
    templateProps.layout.Row2 = David0705_Footer
    templateProps.layout.Col1 = David0705_SidePaneMenu
    templateProps.layout.Col2 = ChipsOverView
    templateProps.layout.login = <Preset_LoginPageOfCarousel resources={[{ image: [true, ''] }]} />
  },
}
