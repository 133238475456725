import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { scrollbar2Css } from '~/css/scrollbarCss'
import {
  fill_horizontal_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { MaRatio } from './component/MaRatio'
import { MaTrend } from './component/MaTrend'
import { RangeZone } from './component/RangeZone'

export const David0705_SidePane2 = memo<ReactProps>(function David0705_SidePane2() {
  return (
    <div
      css={css`
        ${fill_vertical_cross_center};
        width: 336px;
        padding: 4px;
        ${scrollbar2Css};
        background-color: #161616;
      `}
    >
      <styleds.sidebarTitle>高勝1</styleds.sidebarTitle>
      <MaRatio />
      <styleds.sidebarTitle>高勝2</styleds.sidebarTitle>
      <MaTrend />
      <styleds.sidebarTitle>空間感</styleds.sidebarTitle>
      <div
        css={css`
          width: 100%;
          height: calc(100% - 548px);
        `}
      >
        <RangeZone />
      </div>
    </div>
  )
})

const styleds = {
  sidebarTitle: styled.div`
    ${fill_horizontal_all_center};
    height: 32px;
    background-color: #aa2d2d;
    color: #efefef;
    border-radius: 4px;
  `,
}
