import { css } from '@emotion/react'
import { memo } from 'react'
import { AppLink2 } from '~/components/AppLink2'
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss'

export const David0705_SidePaneMenu = memo<ReactProps>(function David0705_SidePaneMenu() {
  return (
    <div
      css={css`
        ${fill_vertical_cross_center};
        background-color: #161616;
        border-right: 1px solid #555555;
        gap: 4px;

        & > * {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 30px;
          color: #ffffff;

          &:hover {
            background-color: #333333;
            color: #ffffff;
          }
        }
      `}
    >
      <AppLink2 href={'/heineken_template'}>高勝指標</AppLink2>
      <AppLink2 href={'/david0705/trading_analyzer'}>交易健檢</AppLink2>
      <AppLink2 href={'/david0705/chips_overview'}>大盤籌碼</AppLink2>
      <AppLink2 href={'/heineken_template/goodway/monitors'}>--</AppLink2>
      <AppLink2 href={'/heineken_template/goodway/monitors'}>--</AppLink2>
    </div>
  )
})

//這次嘗試將
//`pages/heineken_template/goodway/monitors/index` 放置對應agent中資料夾

//todo: 新版閹割籌碼表、交易健康檢查、當日成交量
