import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import {
  fill,
  fill_horizontal_all_center,
  fill_vertical_all_center,
} from '~/modules/AppLayout/FlexGridCss'
import { AppLink2 } from '~/components/AppLink2'
import { AiFillHome, AiOutlineBarChart } from 'react-icons/ai'

import { IconType } from 'react-icons'
import { RiBarChartHorizontalFill, RiCoinsFill } from 'react-icons/ri'
import { GiHealthNormal } from 'react-icons/gi'

import { proxy, useSnapshot } from 'valtio'

/** 格式 */
type BottomMenu = {
  url: string
  favicon: string | IconType
  name: string
}

const urlConfig = [
  { url: '/heineken_template', favicon: <AiFillHome size={20} />, name: '高勝' },
  { url: '/david0705/trading_analyzer', favicon: <GiHealthNormal size={18} />, name: '健檢' },
  { url: '/david0705/chips_overview', favicon: <RiCoinsFill size={20} />, name: '籌碼' },
  { url: '/david0705/', favicon: <AiOutlineBarChart size={20} />, name: '圖表' },
  { url: '/david0705/c', favicon: <RiBarChartHorizontalFill size={20} />, name: '排行' },
]

const Selected = memo<ReactProps>(function Selected() {
  return (
    <div
      css={css`
        width: 48%;
        border-bottom: 2px solid #ffffff;
        line-height: 4px;
      `}
    >
      &nbsp;
    </div>
  )
})

export const David0705_BottomMenu = memo<ReactProps>(function David0705_SidePaneMenu() {
  const pageStore = proxy({
    state: urlConfig[0].name as BottomMenu['name'],
  })

  const pageState = useSnapshot(pageStore).state

  return (
    <classes.Root css={baseStyled}>
      <classes.container>
        {urlConfig.map((config, index) => {
          return (
            <classes.item
              key={index}
              //onClick={() => (pageStore.state = config.name)}
            >
              <AppLink2
                href={config.url}
                css={css`
                  ${fill_vertical_all_center};
                `}
              >
                <classes.favicon>{config.favicon}</classes.favicon>
                <classes.name>{config.name}</classes.name>
                {config.name === pageState && <Selected />}
              </AppLink2>
            </classes.item>
          )
        })}
      </classes.container>
    </classes.Root>
  )
})

const classes = {
  Root: styled.div`
    ${fill}
    height: 48px;
  `,
  container: styled.div`
    ${fill_horizontal_all_center};
    gap: 8px;
    padding: 4px;
  `,
  item: styled.div`
    ${fill_horizontal_all_center};
    height: 100%;
    width: 56px;
    border-radius: 8px;
    &:hover {
      background-color: #343c5a;
    }

    &:active {
      background-color: #444c6a;
    }

    & > * {
      color: #ffffff;
    }
    &:hover {
      color: #ffffff;
    }
  `,
  favicon: styled.div`
    line-height: 16px;
  `,
  name: styled.div`
    font-size: 12px;
    line-height: 14px;
  `,
  selected: styled.div`
    ${fill_horizontal_all_center};
    height: 48px;
  `,
}

const baseStyled = css`
  ${classes.Root} {
  }
  ${classes.container} {
    background-color: #242c4a;
  }
  ${classes.item} {
  }
`
